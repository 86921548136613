import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  ::-webkit-scrollbar, body ::-webkit-scrollbar {
    all: unset;

  }

  /* Track */
  body ::-webkit-scrollbar-track {
    all: unset;
  }

  /* Handle */
  body ::-webkit-scrollbar-thumb {
    all: unset;
  }
  body ::-webkit-scrollbar-thumb:window-inactive {
    all: unset;
  }

  body > iframe { display: none; }

  #root {
    display: flex;
    flex-direction: column;
  }

  .mdn-Container {
    flex: 1;
  }

  .mdn-Footer:not(.mdn-Footer--signature) .mdn-Footer-disclaimer a {
    color: #fff;
  }
  .mdn-Footer:not(.mdn-Footer--signature) .mdn-Footer-content {
    justify-content: space-between;
  }

  .mdn-Header {
    padding-bottom: 0;
    padding-top: 0;
    min-height: 49px;

    .mdn-Container {
      height: 100%;
      .mdn-Header--simple-content {
        height: 100%;
        .mdn-logoSvg {
          max-width: 181px;
        }
      }
    }

  }

`;
