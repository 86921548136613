import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from '~/styles/global';
import { Loading } from './components/Loading';

// import { FooterBar } from './components/FooterBar';
// import { HeaderBar } from './components/HeaderBar';
// import { JourneyProvider } from './hooks/journey';
// import Journey from './pages/journey';

const JourneyProvider = React.lazy(() =>
  import('./hooks/journey').then(({ JourneyProvider }) => ({
    default: JourneyProvider,
  })),
);
const HeaderBar = React.lazy(() =>
  import('./components/HeaderBar').then(({ HeaderBar }) => ({
    default: HeaderBar,
  })),
);
const Journey = React.lazy(() => import('./pages/journey'));

export default function App() {
  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === 'development' ? '/' : '/app'}
    >
      <Suspense fallback={<Loading />}>
        <JourneyProvider>
          <HeaderBar />
          <Journey />
        </JourneyProvider>
      </Suspense>
      <GlobalStyles />
    </BrowserRouter>
  );
}
