// import loadingCircle from '~/assets/images/loading-circle.gif';

import { LoadingBackground } from './styles';

export function Loading() {
  return (
    <LoadingBackground>
      {/* <img src={loadingCircle} alt="loading-circle" /> */}
      <div className="loadingio-spinner-rolling-9ouu1vu7br">
        <div className="ldio-sz55ioxsvxp">
          <div />
        </div>
      </div>
    </LoadingBackground>
  );
}
