import styled from 'styled-components';

export const LoadingBackground = styled.div`
  z-index: 9999;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.4);

  @keyframes ldio-sz55ioxsvxp {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-sz55ioxsvxp div {
    position: absolute;
    width: 77px;
    height: 77px;
    border: 5px solid #919191;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-sz55ioxsvxp div {
    animation: ldio-sz55ioxsvxp 1s linear infinite;
    top: 50px;
    left: 50px;
  }
  .loadingio-spinner-rolling-9ouu1vu7br {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    background: rgba(NaN, NaN, NaN, 0);
  }
  .ldio-sz55ioxsvxp {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.34);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-sz55ioxsvxp div {
    box-sizing: content-box;
  }
`;
